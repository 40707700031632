import React, { useEffect, useState } from "react";

import Card from "./Card/Card";
import "./Cards.css";

const Cards = ({ cardList }) => {
  const [animating, setAnimating] = useState(false);
  const [backCards, setBackCards] = useState([]);
  const [behindId, setBehindId] = useState(null);
  const [currentId, setCurrentId] = useState(null);
  const [forwardId, setForwardId] = useState(null);

  useEffect(() => {
    if (cardList.length && !currentId) {
      const currentCard = cardList[cardList.length - 1];
      setCurrentId(currentCard.id);
    }
  }, [cardList, currentId]);

  const resetAnimation = () => {
    setBehindId(null);
    setForwardId(null);
    setAnimating(false);
  };

  const finishAnimationBehind = () => {
    const currentIndex = cardList.findIndex(card => card.id === currentId) || 0;
    const nextIndex = currentIndex === 0 ? cardList.length - 1 : currentIndex - 1; 

    // Send current card to the back 
    // If all cards are "back", reset the array
    if (currentIndex === 0) {
      setBackCards([]);
    } else {
      setBackCards(backCards.concat(currentId));
    }

    // Make next card current
    setCurrentId(cardList[nextIndex].id);

    resetAnimation();
  };

  const getNextId = () => {
    setAnimating(true);
    
    // Add backward animation on current index
    setBehindId(currentId);
  };

  const finishAnimationForward = (id) => {
    // Remove current card from back 
    // If all cards are "forwards", send the rest back
    if (backCards.length) {
      setBackCards(backCards.filter((backId) => backId !== id));
    }

    // Set previous index as current
    setCurrentId(id);
    
    resetAnimation();
  };

  const getPreviousId = () => {
    setAnimating(true);

    // Add forward animaction to previous index
    const currentIndex = cardList.findIndex(card => card.id === currentId) || 0;
    const previousIndex = currentIndex === cardList.length - 1 ? 0 : currentIndex + 1;

    if (backCards.length === 0) { 
      const restCards = cardList.filter((card) => card.id !== cardList[previousIndex].id);
      setBackCards(restCards.map((card) => card.id));
    }

    setForwardId(cardList[previousIndex].id);
  };
  
  const handleKeyUp = (event) => {
    if (animating) {
      return;
    }
    
    if (event.key === "ArrowRight") {
      getNextId();
    } else if (event.key === "ArrowLeft") {
      getPreviousId();
    }
  };
  
  const handleClick = () => {
    if (animating) {
      return;
    }
    
    getNextId();
  };

  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp);
    
    return () => {
      window.removeEventListener('keyup', handleKeyUp);
    };
  });
  
  return (
    <section className="Cards">
      <div className="Cards__stack" onClick={handleClick}>
        {cardList ? cardList.map((card) => (
          <Card
            data={card}
            isInBack={backCards.includes(card.id)}
            key={card.id}
            startAnimationBehind={behindId === card.id}
            startAnimationForward={forwardId === card.id}
            finishAnimationBehind={finishAnimationBehind}
            finishAnimationForward={finishAnimationForward}
          />
        )) : null}
      </div>
    </section>
  );
};

export default Cards;