import React from "react";

import "./Page.css";

const Page = ({ children, header }) => {
  return (
    <section className="Page">
      <header className="Page__header">
        <h1>{header}</h1>
      </header>
      <div className="Page__content-wrapper">
        <div className="Page__content">
          {children}
        </div>
      </div>
    </section>
  );
};

export default Page;