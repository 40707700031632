import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Desk from "../components/Desk/Desk";

import Details from "./Details/Details";
import Travel from "./Travel/Travel";
import Home from "./Home/Home";
import Registry from './Registry/Registry';
import RSVP from "./RSVP/RSVP";
import Story from "./Story/Story";

import "./App.css";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Desk />}>
          <Route index element={<Home />} />
          <Route path="details" element={<Details />} />
          <Route path="story" element={<Story />} />
          <Route path="travel" element={<Travel />} />
          {/* <Route path="rsvp" element={<RSVP />} /> */}
          <Route path="registry" element={<Registry/>}></Route>
          <Route path="*" />
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
