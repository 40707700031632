import React, { useEffect, useState } from "react";
import { Link, useResolvedPath, useMatch, useLocation } from "react-router-dom";


import "./Navigation.css"

const NavigationLink = ({ children, color, to }) => {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });
  
  return (
    <Link className={`Navigation__link Navigation__link--${color}${ match ? ' Navigation__link--active' : ''}`} to={to}>
      <span className="Navigation__link__title">{children}</span>
    </Link>
  );
};

const Navigation = () => {
  const [showMenu, setShowMenu] = useState(false);
  const { pathname } = useLocation();
  
  useEffect(() => {
    window.scrollTo(0, 0);
    setShowMenu(false);
  }, [pathname]);

  return (
    <div className="Navigation__wrapper">
      <ul className={`Navigation${ showMenu ? ' Navigation--show' : ''}`}>
        <li>
          <NavigationLink to="/" color="orange">Home</NavigationLink>
        </li>
        <li>
          <NavigationLink to="/details" color="green">Details</NavigationLink>
        </li>
        {/* <li>
          <NavigationLink to="/rsvp" color="yellow">RSVP</NavigationLink>
        </li> */}
        <li>
          <NavigationLink to="/registry" color="purple">Registry</NavigationLink>
        </li>
      </ul>
      <button className="Navigation__toggle" onClick={() => setShowMenu(!showMenu)}>
        <span className="Navigation__toggle__label">Menu</span>
        <span className="Navigation__toggle__icon">{showMenu ? 'Close menu' : 'Open menu'}</span>
      </button>
    </div>
  );
};

export default Navigation;
