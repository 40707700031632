import React, { useEffect, useRef } from "react";

import "./Registry.css"

const Registry = () => {
  const wrapperEl = useRef(null);

  useEffect(() => {
    const script = document.createElement('script');

    script.src = "https://www.myregistry.com//Visitors/GiftList/iFrames/EmbedRegistry.ashx?r=UgJg-29cvnGM2rCTifJOYA2&v=2";
    script.id= "script_myregistry_giftlist_iframe";
    script.async = true;

    wrapperEl.current?.appendChild(script);

    return () => {
      wrapperEl.current?.removeChild(script);
    }
  }, []);

  return (
    <div className="Registry__wrapper" ref={wrapperEl} />
  );
};

export default Registry;
