import React from "react";

import "./Card.css";

const Card = ({ 
  data, 
  isInBack,
  startAnimationBehind,
  startAnimationForward,
  finishAnimationForward,
  finishAnimationBehind, 
}) =>  {

  const getClasses = () => {
    let classes = ['Card'];

    if (isInBack) {
      classes.push('Card--back');
    }

    if (startAnimationForward) {
      classes.push('Card--animate-forwards')
    }

    if (startAnimationBehind) {
      classes.push('Card--animate-behind')
    }

    return classes.join(" ");
  }

  const handleAnimationEnd = () => {
    if (startAnimationForward) {
      finishAnimationForward(id);
    } else if (startAnimationBehind) {
      finishAnimationBehind();
    }
  }

  const { id, header, content } = data;
  return (
    <div id={id} className={getClasses()} onAnimationEnd={handleAnimationEnd}>
      {header ? (<h3 className="Card__heading">{header}</h3>) : null}
      {content}
    </div>
  )
};

export default Card;