import React from "react";

import "./PageSection.css";

const PageSection = ({ children, className, header, layout, sidebar }) => {
  return (
    <section className={`Page__section ${className || ''}${layout === 'reversed' ? ' Page__section--reversed' : ''}`}>
      <div className="Page__section__details">
        {header ? (<h2>{header}</h2>) : ''}
        {children}
      </div>
      {sidebar}
    </section>
  );
};

export default PageSection;