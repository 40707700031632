import Note from "../../components/Note/Note";
import Page from "../../components/Page/Page";
import PageSection from "../../components/Page/PageSection/PageSection";
import Polaroid from "../../components/Polaroid/Polaroid";
import { Link } from "react-router-dom";

import BOKPhoto from '../../assets/photos/BOK-auditorium.jpg';
import UsPhoto from '../../assets/photos/erica-spencer-rings.jpg';
import HeartArrow from '../../assets/sketches/sketch-heart-arrow.png';
import HeartArrow2x from '../../assets/sketches/sketch-heart-arrow-2x.png';
import HeartCloud from '../../assets/sketches/sketch-heart-cloud-alt.png';
import HeartCloud2x from '../../assets/sketches/sketch-heart-cloud-alt-2x.png';
import HeartNotes from '../../assets/sketches/sketch-music-notes.png';
import HeartNotes2x from '../../assets/sketches/sketch-music-notes-2x.png';
import SpencerEricaCoolS from '../../assets/sketches/sketch-spencer-erica-cool-s.png';
import SpencerEricaCoolS2x from '../../assets/sketches/sketch-spencer-erica-cool-s-2x.png';

import "./Home.css"

const Home = () => {
  return (
    <Page header="Spencer &amp; Erica are getting married!">
      <PageSection
        header="Date"
        sidebar={<Polaroid alt="our engagement photo" src={UsPhoto} taped="alternate" />}
      >
        <p>September 17, 2022</p>
        <p><strong>We'll see you all SO SOON!</strong></p>
        <img src={SpencerEricaCoolS} srcSet={`${SpencerEricaCoolS} 1x, ${SpencerEricaCoolS2x} 2x`} alt="" className="Home__doodle--date"/>
      </PageSection>
      <PageSection
        header="Location"
        layout="reversed"
        sidebar={<Polaroid alt="BOK building" src={BOKPhoto} taped angle="-4" />}
      >
        <p>BOK Building<br/> Philadelphia, PA</p>
        <a href="https://www.buildingbok.com/history" target="_blank" rel="noreferrer noopener">Learn about the venue!</a>
        <img srcSet={`${HeartNotes} 1x, ${HeartNotes2x} 2x`} alt="" className="Home__doodle--location"/>
      </PageSection>
      <PageSection
        header="Accommodations"
        sidebar={
          <Note color="pink" className="Home__details__note">
          <div>
            <p>To Do:</p>
            <ul>
              <li>- <Link to="/story">Study for quiz!</Link></li>
            </ul>
          </div>
          <img src={HeartArrow} srcSet={`${HeartArrow} 1x, ${HeartArrow2x} 2x`} alt="" className="Home__doodle--details-note"/>
        </Note>
        }
      >
        <p>We've booked some hotel blocks within the city for your convenience.</p>
        <Link to="/travel">Get the details!</Link>
        <img src={HeartCloud} srcSet={`${HeartCloud} 1x, ${HeartCloud2x} 2x`} alt="" className="Home__doodle--details"/>
      </PageSection>
    </Page>
  );
};

export default Home;
