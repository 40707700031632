import React from "react";
import { Outlet } from "react-router-dom";

import Navigation from "../Navigation/Navigation";

import "./Desk.css";

const Desk = () => {
  return (
    <div className="Desk">
      <Navigation/>
      <Outlet />
    </div>
  );
};

export default Desk;