import Page from "../../components/Page/Page";
import PageSection from "../../components/Page/PageSection/PageSection";
import Polaroid from "../../components/Polaroid/Polaroid";

import "./Travel.css"

import HotelMap from '../../assets/photos/hotel-map.jpg';

const Travel = () => {
  return (
    <Page header="Welcome to Philly!">
      <PageSection
        className="Travel__accomodations"
        header="Accommodations"
        sidebar={<Polaroid alt="hotel map" src={HotelMap} taped="alternate" angle="-4" className="Travel__polaroid--hotel"/>}
      >
        <p>We've made arrangements with several hotels in Philadelphia for your stay!</p>
      </PageSection>

      <PageSection
        header="The Study"
        layout="reversed"
      >
        <p>The Study at University City is located right next to the 30th Street Train Station.</p>
        <a href="https://reservations.travelclick.com/99716?groupID=3406285" target="_blank" rel="noreferrer noopener">Make a reservation</a>
      </PageSection>
      <PageSection
        header="Doubletree"
      >
        <p>DoubleTree Center City is located on the Avenue of the Arts, right downtown.</p>
        <a href="https://book.passkey.com/e/50289199" target="_blank" rel="noreferrer noopener">Make a reservation</a>
      </PageSection>
      <PageSection
        header="Loews Hotel"
        layout="reversed"
      >
        <p>We've arranged a special 20% discount with the Loews Hotel, which is also downtown!</p>
        <a href="https://www.loewshotels.com/philadelphia-hotel/specials/wed-q3" target="_blank" rel="noreferrer noopener">Make a reservation</a>
      </PageSection>
    </Page>
  );
};

export default Travel;
