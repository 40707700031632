import Cards from "../../components/Cards/Cards";

import CovidDoodle from "../../assets/sketches/sketch-covid.png";
import CovidDoodle2x from "../../assets/sketches/sketch-covid-2x.png";
import Sperica4eva from "../../assets/sketches/sketch-spencer+erica-4eva.png";
import Sperica4eva2x from "../../assets/sketches/sketch-spencer+erica-4eva-2x.png";
import SunDoodle from "../../assets/sketches/sketch-sun.png";
import SunDoodle2x from "../../assets/sketches/sketch-sun-2x.png";
import Survey from "../../assets/sketches/sketch-do-you-like-me.png";
import Survey2x from "../../assets/sketches/sketch-do-you-like-me-2x.png";
import ThreeHearts from "../../assets/sketches/sketch-three-small-hearts.png";
import ThreeHearts2x from "../../assets/sketches/sketch-three-small-hearts-2x.png";
import TinyHeart from "../../assets/sketches/sketch-heart.png";
import TinyHeart2x from "../../assets/sketches/sketch-heart-2x.png";

import './Story.css';

const Story = () => {
  return (
    <Cards 
      cardList={[
        {
          id:"bonus", 
          content: (
            <>
              <img alt="" src={Survey} srcSet={`${Survey} 1x, ${Survey2x} 2x`} className="Story__do-you-like-me"/>
            </>
          )
        },
        {
          id:"09172022", 
          header: "September 17, 2022",
          content: (
            <>
              <p>Seven years, four apartments, two coasts, and one pandemic later, we're finally making it official!</p>
              <p>And we're so excited to share the occasion with all of you :)</p>
              <img alt="" src={Sperica4eva} srcSet={`${Sperica4eva} 1x, ${Sperica4eva2x} 2x`} className="Story__sperica-4eva"/>
            </>
          )
        },
        {
          id:"06012020", 
          header: "Summer, 2020", 
          content: (
            <>
              <p>2020 was not, in fact, a great year.</p>
              <p>But we decided to make the trek anyway. We saw a lot of beautiful National Parks and almost none of the people we planned to visit.</p>
              <p>We ended up in Philadelphia, and while COVID's still making things weird, it's starting to feel a little more like home.</p>
              <img alt="" src={CovidDoodle} srcSet={`${CovidDoodle} 1x, ${CovidDoodle2x} 2x`} className="Story__covid"/>
            </>
          )
        },
        {
          id:"12012019", 
          header: "Late 2019", 
          content: (
            <>
              <p>We decided to move to the East Coast. We'd make a big road trip of it, visiting friends and family along the way. Once we got out here, we'd explore some potential cities to live in. After we got settled, we'd start wedding planning.</p>
              <p>2020 was going to be a great year!</p>
              <img alt="" src={SunDoodle} srcSet={`${SunDoodle} 1x, ${SunDoodle2x} 2x`} className="Story__sun"/>
            </>
          )
        },
        {
          id:"05302019", 
          header: "May 30, 2019",
          content: (
            <>
              <img alt="" src={TinyHeart} srcSet={`${TinyHeart} 1x, ${TinyHeart2x} 2x`} className="Story__engagement-heart"/>
              <p>We got engaged! We discussed it a lot beforehand, and even planned a weekend in Mendocino to make it official.</p>
              <p>Spencer proposed the Thursday before we left, in the park where he first said “I love you”.</p>
              <p className="Card__note spencer">I had the rings in my pocket. Erica had no idea. She saw a “good climbing tree” and so we climbed up and it just felt like the right moment. She was …surprised.</p>
              <p className="Card__note erica">This was not the plan. I believe my response to the proposal was: “You're joking. You're joking? Do we have to wear the rings to work tomorrow?”</p>
            </>
          )
        },
        {
          id:"07012017", 
          header: "July 1, 2017",
          content: (
            <>
              <p>We moved into our first place together! It was in a “desirable neighborhood”, which meant lots of fun bars to go out to.</p>

              <p className="Card__note spencer">We went out to the fun bars approximately twice</p>
              <p className="Card__note erica">I don't think we were cool enough for The Mission</p>

              <p>Stepping over the puddle of human urine at our front gate was… less desirable. We moved pretty quickly.</p>
            </>
          )
        },
        {
          id:"08222015", 
          header: "August 22, 2015",
          content: (
            <>
              <p>We made homemade dumplings and steamed buns.</p>
              <p>It was definitely a date. We both knew it this time.</p>
              <img alt="" src={ThreeHearts} srcSet={`${ThreeHearts} 1x, ${ThreeHearts2x} 2x`} className="Story__three-hearts"/>
            </>
          )
        },
        {
          id:"08022015S", 
          header: "August 2, 2015 - Spencer's version",
          content: (
            <>
              <p className="Card__note spencer">Erica invited me over to watch a silly vampire comedy. I thought it was going to be like, a group hangout? Turns out it was just the two of us.</p>
              <p className="Card__note spencer">Even so, I couldn't tell if this was still just a friend thing? Erica was giving me nothing to go on, and I didn't want to make things weird. We worked together! I played it cool. Definitely not a date.</p>
              <p className="Card__note spencer">But maybe there was something there? I took a risk and asked her out on a date: making homemade dumplings and steamed buns</p>
            </>
          )
        },
        {
          id:"08022015E", 
          header: "August 2, 2015 - Erica's version",
          content: (
            <>
              <p className="Card__note erica">I was so proud of myself for making the first move! Very un-Erica like. </p>
              <p className="Card__note erica">I invited Spencer over to watch a movie (What We Do in the Shadows) because “no one would watch it with me”.</p>
              <p className="Card__note erica">I made popcorn! He brought wine! It was definitely a date. AND we made plans to go on a second date: making homemade dumplings and steamed buns!</p>
            </>
          )
        },
        {
          id:"05152015", 
          header: "May, 2015",
          content: (
            <>
              <p>One (late) night, Erica accidentally sent Spencer a friend request on Facebook while stalking his photos.</p>
              <p>Spencer accepted immediately.</p>
              <p className="Card__note erica">I was mortified. I threw my phone.</p>
            </>
          )
        },
        {
          id:"05012015", 
          header: "Summer 2015",
          content: (
            <>
              <p>They ended up sitting across from each other at work.</p>
              <p className="Card__note spencer">I thought Erica was nice!</p>
              <p className="Card__note erica">I couldn't tell how I felt about Spencer at first… was he annoying? Did I like him? I think I like him? It helped that Spencer was not subtle about his feelings </p>
              <p className="Card__note spencer">Erica was giving me no signs. It was very confusing</p>
            </>
          )
        },
        {
          id:"04242015", 
          header: "April 24, 2015",
          content: (
            <>
              <p>Erica interviewed Spencer for a position at Collective Health. Needless to say, he got the job!</p>
              <p className="Card__note erica">While technically proficient, I was not super into the fact that Spencer was flirting throughout the interview.</p>
              <p className="Card__note spencer">I was not! I was just being friendly!</p>
              <p className="Card__note erica">Mmm... definitely flirting</p>
            </>
          )
        },
        {
          id:"intro", 
          header: "Study up on our story!",
          content: (
            <>
              <p>Click the cards or press the arrow keys to flip through the flash cards of our love. There will be a quiz later!</p>
              <p className="Card__note erica">Erica's annotations look like this!</p>
              <p className="Card__note spencer">Spencer's annotations look like this!</p>
            </>
          )
        },
      ]} 
    />
  );
};

export default Story;
