import { Link } from "react-router-dom";
import Page from "../../components/Page/Page";
import PageSection from "../../components/Page/PageSection/PageSection";
import Polaroid from "../../components/Polaroid/Polaroid";
import Croissants from '../../assets/photos/croissants.jpg';
import Philly from '../../assets/photos/philly-skyline.jpg';
import BOKMap from '../../assets/photos/bok-parking-map.png';
import MarriedNames from '../../assets/sketches/sketch-married-names.png';
import MarriedNames2x from '../../assets/sketches/sketch-married-names-2x.png';

import "./Details.css"

const Details = () => {
  return (
    <>
      <Page header="Gimme the deets!">
        <PageSection
          className="Details__accomodations"
          header="Where to stay"
          sidebar={<Polaroid alt="Philly skyline" src={Philly} taped angle="-4" />}
          layout="reversed"
        >
          <p>We've made arrangements with several hotels in Philadelphia for your stay!</p>
          <p>Visit our <Link className="Details__accomodations__link" to="/travel">Travel page</Link> for details</p>
        </PageSection>

        <PageSection
          className="Details__travel"
          header="Getting to BOK"
        >
          <p>There's been a last-minute change in the parking situation. The small lot previously mentioned here has a new owner and is no longer available for our guests.</p>
          <p>There is generally some available street parking on 8th st, but we <strong>extra</strong> encourage you to come via Lyft/Uber/taxi</p>
          
        </PageSection>

        <PageSection
          className="Details__schedule"
          header="Wedding day schedule"
          sidebar=""
        >
          <p>All events will be held at the BOK building</p>
          <p><em>Please use the entrance at 800 Mifflin street</em></p>
          <p><span className="Details__schedule__title">Ceremony</span> - 3pm in the auditorium</p>
          <p><span className="Details__schedule__title">Cocktail hour</span> - 4pm on the 8th floor terrace</p>
          <p><span className="Details__schedule__title">Reception</span> - 5pm in the west gymnasium</p>
        </PageSection>
      </Page>

      <Page header="But wait, there's more!">
        <PageSection
          className="Details__brunch"
          header="Post-wedding brunch"
          sidebar={<Polaroid alt="🤤" src={Croissants} taped angle="-4" />}
        >
          <p><span className="Details__brunch__title">What: </span>Join us for a post-wedding parkside pastry picnic! (say that 10x fast)</p>
          <p><span className="Details__brunch__title">When: </span>Sunday, 9/18 from 10am - 3pm</p>
          <p><span className="Details__brunch__title">Where: </span>At the Belmont Grove in Fairmont Park<br />1800 Belmont Mansion Drive, Philadelphia, PA 19131</p>
          <p>On-site parking available</p>
        </PageSection>

        <PageSection
          className="Details__faqs"
          header="FAQs"
          sidebar=""
        >
          <p><span className="Details__faq__question">Are there any Covid precautions?</span> Yes! Proof of vaccination is required, and there will be someone checking cards at the door (photos are accepted)</p>
          <p><span className="Details__faq__question">What should I wear?</span> What ever makes you feel fab! We're recommending <a href="https://www.theknot.com/content/cocktail-wedding-attire" target="_blank" rel="noreferrer noopener">cocktail attire</a></p>
          <p><span className="Details__faq__question">Are children invited?</span> Yes! Children are welcome</p>
          <img src={MarriedNames} srcSet={`${MarriedNames} 1x, ${MarriedNames2x} 2x`} alt="" className="Details__faq__doodle"/>  
        </PageSection>
      </Page>
    </>
  );
};

export default Details;
