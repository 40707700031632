
import "./Polaroid.css";

const Polaroid = ({ alt, src, taped, angle, children, className }) => (
  <div className={`Polaroid${taped ? ' Polaroid--tape':''}${taped === 'alternate' ? ' Polaroid--tape--alternate' : ''} ${className || ''}`} style={{'--angle': `${angle || 7.5}deg`}}>
    <div className={`Polaroid__image__wrapper`}>
      <img className={`Polaroid__image`} src={src} alt={alt} />
    </div>
    <div className={`Polaroid__label`}>{children}</div>
  </div>
);

export default Polaroid;